import React from 'react';
import { ifProp } from 'styled-tools';
import styled, { css } from 'styled-components';
import { useField, useFormikContext } from 'formik';
import { useRxData } from 'rxdb-hooks';
import { Col, Row } from 'app/components/Grid';
import { useCollapse } from 'react-collapsed';

import { Checkbox } from '../../components/Checkbox';
import Rating from '../../../../components/Rating';
import { media } from '../../../../../styles/media';
import { ButtonIcon } from '../../../../components/ButtonIcon';
import { Icon, IconName } from '../../../../components/Icon';
import { ClassDocType } from '../../../../../database/schemas/class';
import { LessonDocType } from '../../../../../database/schemas/lesson';
import { CollectionName } from '../../../../../database/types';
import { CheckboxWrapper } from '../../components/CheckboxWrapper';
import { useFlashcardCount } from '../../../../hooks/useFlashcardCount';
import { Span } from '../../../../components/Span';

interface Props {
  data: ClassDocType;
  isExpanded: boolean;
  classOnly?: boolean;
  score: number;
  onToggle: () => void;
}

export function ClassRow(props: Props) {
  const { data, isExpanded, classOnly, score, onToggle } = props;
  const { title } = data;
  const [field, meta, helpers] = useField(`classes.${data.id}`);
  const { getToggleProps } = useCollapse({ isExpanded });
  const form = useFormikContext();
  const flashcardsCount = useFlashcardCount({ classId: data.id });

  const { result: lessons } = useRxData<LessonDocType>(
    CollectionName.Lessons,
    collection =>
      collection.find({
        selector: {
          class_id: data.id,
        },
      }),
  );

  const handleCheckboxClick = () => {
    if (field.value?.length === lessons.length) {
      helpers.setValue([]);
    } else if (classOnly) {
      form.setValues({
        classes: {
          [data.id]: lessons.map(lesson => lesson.id),
        },
      });
    } else {
      helpers.setValue(lessons.map(lesson => lesson.id));
    }
  };

  const handleExpandClick = event => {
    event.stopPropagation();
    onToggle();
  };

  return (
    <Wrapper onClick={handleCheckboxClick}>
      <Row>
        <Col gutter={0.5} display="flex">
          <CheckboxWrapper background="yellow">
            <Checkbox
              color="white"
              checked={field.value?.length === lessons.length}
            />
          </CheckboxWrapper>
        </Col>
        <Col gutter={{ xs: 0.5, md: 0 }} xs="auto" alignSelf="center">
          <ClassName>
            <Row>
              <Col xs={12} md="auto" alignSelf="center">
                <Row>
                  <Col xs={12}>
                    <b>{title}</b>
                  </Col>
                  <Col gutterY={0.25}>
                    <Span color="grey">
                      <Row flexWrap="nowrap" alignItems="center" gutter={0.25}>
                        <Col gutter={0.25}>
                          <Icon $size={20} name="stack" $block />
                        </Col>
                        <Col gutter={0.25}>{flashcardsCount}</Col>
                      </Row>
                    </Span>
                  </Col>
                </Row>
              </Col>
              {!classOnly && (
                <Col alignSelf="center" gutterY={{ xs: 0.25, md: 0 }}>
                  <Rating rating={score} numberOfStars={3} />
                </Col>
              )}
            </Row>
          </ClassName>
        </Col>
        {!classOnly && (
          <Col gutter={0.5} display="flex">
            <ExpandButton
              type="button"
              block
              {...getToggleProps({
                onClick: handleExpandClick,
              })}
            >
              <RotatingIcon expanded={isExpanded} name="chevron-down" />
            </ExpandButton>
          </Col>
        )}
      </Row>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  &:hover {
    background-color: rgba(var(--blue-100-rgb), 0.1);
  }
`;

const ClassName = styled.div`
  padding: 0.5rem 0.25rem;

  ${media.md} {
    padding: 1rem 0.5rem;
  }
`;

const ExpandButton = styled(ButtonIcon)`
  padding: 1.25rem 0;
  min-width: 4rem;
  display: inline-flex;
  align-items: flex-start;
  justify-content: center;

  ${media.md} {
    padding: 1.5rem 0.5rem;
    min-width: 4.75rem;
  }

  &:hover {
    color: var(--blue-200);
  }
`;

const RotatingIcon = styled(Icon)<{ expanded?: boolean }>`
  ${ifProp(
    'expanded',
    css`
      transform: rotate(180deg);
    `,
  )}
`;
