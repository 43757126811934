import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import { AuthContext } from '../../context/AuthContext';

export const StripePricingTable = props => {
  const { user } = useContext(AuthContext);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.stripe.com/v3/pricing-table.js';
    script.async = true;

    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  if (!user) return null;

  return React.createElement('stripe-pricing-table', {
    'pricing-table-id':
      user?.userSettings?.pricingTable?.stripe_id ||
      process.env.REACT_APP_DEFAULT_PRICING_TABLE,
    'publishable-key': process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
    'client-reference-id': user.id,
    'customer-email': user.email,
  });
};

StripePricingTable.propTypes = {
  stripePricingTableId: PropTypes.string,
  stripePublishableKey: PropTypes.string,
  clientReferenceId: PropTypes.string,
};
