import styled, { css } from 'styled-components/macro';

export const ellipsisStyles = css`
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  white-space: nowrap;
`;

export const Ellipsis = styled.div`
  ${ellipsisStyles}
`;
