import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { api } from '../../../services/api';
import { PageWrapper } from '../../components/PageWrapper';

import { sharedReplications } from '../../../database/configureDatabase';
import { AxiosError, AxiosResponse } from 'axios';
import { ClassDocType } from '../../../database/schemas/class';
import { Modal } from '../../components/Modal';
import { Col, Row } from '../../components/Grid';
import { Button } from '../../components/Button';
import { Block } from '../../components/Block';
import { Heading } from '../../components/Heading';

export function SharePage() {
  const { t } = useTranslation();
  const { entry, token } = useParams<{ entry: 'class'; token: string }>();
  const navigate = useNavigate();
  const [error, setError] = useState<null | AxiosError>(null);

  const getAccess = async () => {
    try {
      const response: AxiosResponse<ClassDocType> = await api.get(
        `${entry}/share/${token}`,
      );

      for (const replication of Object.values(sharedReplications)) {
        replication?.reSync();
      }

      navigate(`/class/${response.data.id}`, { replace: true });
    } catch (error) {
      setError(error as AxiosError);
    }
  };

  useEffect(() => {
    getAccess();
  }, []);

  return (
    <PageWrapper
      loading={!error}
      loadingMessage={t('message.synchronizingData')}
    >
      <Modal isOpen>
        <Block textCenter>
          <Row gutterY={2} justifyContent="center">
            <Col xs={12} gutterY={2}>
              <Heading level={3} weight={600}>
                {t('message.shareError')}
              </Heading>
            </Col>
            <Col gutterY={2}>
              <Button yellow to={`/`}>
                {t('button.close')}
              </Button>
            </Col>
          </Row>
        </Block>
      </Modal>
    </PageWrapper>
  );
}
