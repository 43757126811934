import { useRxCollection } from 'rxdb-hooks';
import { useContext, useMemo } from 'react';
import _max from 'lodash/max';

import { FlashCardDocType } from '../../database/schemas/flashcard';
import { CollectionName } from '../../database/types';
import { AuthContext } from '../context/AuthContext';

const freeFlashcardLimit = +(process.env.REACT_APP_FREE_FLASHCARDS || 10);

export function useFlashcardsLimit() {
  const flashCardCollection = useRxCollection<FlashCardDocType>(
    CollectionName.FlashCards,
  );
  const { subscription, user } = useContext(AuthContext);

  const limit = useMemo(
    () =>
      _max([
        subscription?.metadata?.limit,
        user?.userSettings?.free_plan_max_flashcards || freeFlashcardLimit,
      ]),
    [subscription?.id],
  );

  const getCount = async () => {
    const query = flashCardCollection?.count({
      selector: {
        shared: {
          $not: true,
        },
      },
    });
    const count = await query?.exec();

    return count;
  };

  const checkIfLimitReached = async () => {
    const count = await getCount();
    return limit && count && count >= limit;
  };

  return {
    limit,
    getCount,
    checkIfLimitReached,
  };
}
