import {
  AnswerType,
  FlashcardExport,
  MultipleChoice as MultipleChoiceType,
} from '../types/FlashCard';
import { ClassDocType } from '../database/schemas/class';
import { LessonDocType } from '../database/schemas/lesson';
import { FlashCardDocType } from '../database/schemas/flashcard';
import { normalizeMultipleChoice } from './multiple-choice-normalizer';

export function flashcardToExportFormat(
  classDoc: ClassDocType,
  lesson: LessonDocType,
  flashcard: FlashCardDocType,
): FlashcardExport {
  const { title, author, language } = classDoc;
  const lessonName = lesson.title;
  const { question_type, question_value, answer_type, answer_value } =
    flashcard;

  let answerValue;

  switch (answer_type) {
    case AnswerType.MultipleChoice:
      {
        const answer: MultipleChoiceType =
          typeof answer_value === 'string'
            ? JSON.parse(answer_value)
            : answer_value;
        const answerNormalized = normalizeMultipleChoice(answer);
        answerValue = answerNormalized.options
          .map((option, index) => {
            if (answerNormalized.correctIndex.includes(index)) {
              return `true|${option}`;
            }
            return `false|${option}`;
          })
          .join(';');
      }
      break;
    case AnswerType.Text: {
      answerValue = answer_value;
    }
  }

  return {
    class_name: title,
    class_author: author,
    class_language: language,
    lesson_name: lessonName,
    content_type: 'flashcard',
    question_type,
    question_value,
    answer_type,
    answer_value: answerValue,
  };
}

export function exportFormatToFlashcard(
  flashcard: FlashcardExport,
): Pick<
  FlashCardDocType,
  'question_type' | 'question_value' | 'answer_type' | 'answer_value' | 'shared'
> {
  const { question_type, question_value, answer_type, answer_value } =
    flashcard;

  let answerValue;

  switch (answer_type) {
    case AnswerType.Text: {
      answerValue = answer_value;
      break;
    }
    case AnswerType.MultipleChoice: {
      const answers = answer_value.split(';');
      const options: string[] = [];
      const correctIndex: number[] = [];

      answers.map((answer, index) => {
        const option = answer.slice(answer.indexOf('|') + 1);
        if (answer.includes('true|')) {
          correctIndex.push(index);
        }
        options.push(option);
      });
      answerValue = { options, correctIndex };
      break;
    }
  }

  return {
    question_type,
    question_value,
    answer_type: answer_type || null,
    answer_value: answerValue,
    shared: false,
  };
}
